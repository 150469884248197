import React, { useEffect } from "react";
import { TVChartContainer } from "./components/tradingViewChart";
import { setLocalStorageItem } from "./trading-view/helper";

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const symbol = searchParams.get("symbol");
  const intent = searchParams.get("intent");
  const startDate = searchParams.get("date");

  useEffect(() => {
    if (intent && startDate) {
      let data = {
        intent: intent,
        startDate: startDate,
      };
      setLocalStorageItem("tvData", data);
    }
  }, [intent]);

  return (
    <div className="App">
      {intent && symbol && <TVChartContainer symbols={symbol} />}
    </div>
  );
}

export default App;

import { io } from "socket.io-client";
import { endsWith, find, isEmpty, remove, toLower, values } from "lodash";
import {
  makeApiRequest,
  getSymbol,
  logMessage,
  getErrorMessage,
  logErrorMessage,
  allSymbols,
  isValidSymbol,
  getParsedStorage,
} from "./helper.js";
import { API_URL } from "./const.js";
import moment from "moment/moment.js";

const _barSubscriptions = {};
const _quoteSubscriptions = {};
const _barSymbolSubscribers = {};
const _quoteSymbolSubscribers = {};
let onCloseCallback = null;
let isFirstCall = false;

// DatafeedConfiguration implementation
const configurationData = {
  supports_search: false,
  supports_group_request: true,
  supports_marks: false,
  supports_timescale_marks: true,
  supports_time: true,
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: [
    // "1T",
    // "1S",
    // "5S",
    // "10S",
    // "15S",
    // "30S",
    "1",
    "3",
    "5",
    "15",
    "30",
    // "45",
    "60",
    "120",
    "180",
    "240",
    "480",
    "1D",
    "1W",
    "1M",
    // "3M",
    // "6M",
    // "12M",
  ],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: "", name: "C", desc: "$" },
    { value: "OTC", name: "OTC", desc: "Over the counter" },
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    { name: "All", value: "" },
    { name: "Forex", value: "forex" },
    { name: "Indices", value: "index" },
    { name: "Commodity", value: "commodity" },
    // { name: "Crypto", value: "crypto" },
  ],
};

function calculatePriceScale(decimals) {
  if (decimals == undefined || decimals == null || decimals < 0) {
    decimals = 2;
  }
  return Math.pow(10, decimals);
}

function getSymbolStringForHistorical(symbol, symbolType, resolution) {
  return `${symbol}_${getIntervalFormate(resolution)}`;
}

function getIntervalFormate(resolution) {
  const resolutionMap = {
    "1T": "tick",
    "1S": "1S",
    "5S": "S5",
    "10S": "S10",
    "15S": "S15",
    "30S": "S30",
    1: "M1",
    3: "M3",
    5: "M5",
    15: "M15",
    30: "M30",
    45: "M45",
    60: "H1",
    120: "H2",
    180: "H3",
    240: "H4",
    480: "H8",
    "1D": "D1",
    "1W": "W1",
    "1M": "MN",
    "3M": "3MN",
    "6M": "6MN",
    "12M": "12MN",
  };

  return resolutionMap[resolution];
}

export const dataFeeder = {
  onReady: (callback) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },
  registerOnCloseCallback: (callback) => {
    onCloseCallback = callback;
  },

  unregisterOnCloseCallback: (callback) => {
    if (onCloseCallback === callback) {
      onCloseCallback = null;
    }
  },
  searchSymbols: async (
    userInput,
    exchange,
    symbolType,
    onResultReadyCallback
  ) => {
    logMessage(
      `searchSymbol: Searching symbols #${userInput} - {${symbolType}}`
    );
    const newSymbols = values(allSymbols).filter((symbol) => {
      const isExchangeValid = exchange === "" || symbol.exchange === exchange;
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      const isSymbolDescriptionContainsInput =
        symbol.description.toLowerCase().indexOf(userInput.toLowerCase()) !==
        -1;
      let typeRes = true;
      if (symbolType) {
        typeRes = symbolType === symbol.type;
      }
      return (
        typeRes &&
        isExchangeValid &&
        (isFullSymbolContainsInput || isSymbolDescriptionContainsInput)
      );
    });
    onResultReadyCallback(newSymbols);
  },

  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    setTimeout(() => {
      logMessage(`resolveSymbol: Resolving symbol #${symbolName}`);
      const symbol = getSymbol(symbolName);
      if (!symbol) {
        logMessage(`resolveSymbol: Can not resolving symbol #${symbolName}`);
        onResolveErrorCallback("Cannot resolve symbol");
        return;
      }
      // Symbol information object
      const symbolInfo = {
        ticker: symbol.full_name,
        name: symbol.symbol,
        description: symbol.description,
        type: symbol.type,
        typespecs: symbol.type == "forex" ? [] : ["cfd"],
        session: symbol.session,
        timezone: "Etc/UTC",
        exchange: symbol.exchange,
        minmov: 1,
        pricescale: calculatePriceScale(symbol.decimals),
        visible_plots_set: "ohlcv",
        has_ticks: true, // Supports resolution in ticks
        has_seconds: true, // Supports resolution in seconds
        has_intraday: true, // Supports resolution in minutes
        has_daily: true, // Supports resolution in days
        has_weekly_and_monthly: true, // Supports resolution in weeks/months
        volume_precision: 2,
        data_status: "streaming",
        logo_urls: symbol.logo_urls,
      };
      logMessage(`resolveSymbol: Resolved symbol #${symbolName}`, symbolInfo);
      onSymbolResolvedCallback(symbolInfo);
    }, 0);
  },

  getBars: async (
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) => {
    let { from, to, firstDataRequest } = periodParams;
    let { name: symbol, type: symbolType } = symbolInfo;
    const symbolPattern = getSymbolStringForHistorical(
      symbol,
      symbolType,
      resolution
    );
    const intervalPattern = getIntervalFormate(resolution);

    const tvData = getParsedStorage("tvData");

    const fromDate = moment(tvData.startDate, "YYYY.MM.DD HH:mm:ss")
      .subtract(24, "hours")
      .unix();

    const toDate = moment(tvData.startDate, "YYYY.MM.DD HH:mm:ss")
      .add(24, "hours")
      .unix();

    const token = tvData.intent;

    if (!isFirstCall) {
      try {
        const candles = await makeApiRequest(
          "tv-trade-history",
          {
            symbol: symbol,
            from: fromDate * 1000,
            to: toDate * 1000,
            interval: intervalPattern,
          },
          API_URL,
          token
        );
        logMessage("getBars: Historical candles #", candles);
        let bars = [];
        candles.forEach((bar) => {
          if (bar.time >= fromDate * 1000 && bar.time <= toDate * 1000) {
            let { open, high, low, close, time, volume } = bar;
            bars = [
              ...bars,
              {
                time,
                low,
                high,
                open,
                close,
                volume,
              },
            ];
          }
        });
        logMessage(`getBars: Returned bars count ${bars.length}`);
        if (firstDataRequest) {
          const lastBar = bars[bars.length - 1];
          logMessage("getBars: Last bar #", lastBar);
          _barSymbolSubscribers[symbolPattern] = {
            lastBarTime: lastBar?.time,
            resolution,
            symbolInfo,
            listeners: [],
          };
        }
        onHistoryCallback(bars, { noData: false });
        isFirstCall = true;
      } catch (e) {
        logErrorMessage(`getBars: ${getErrorMessage(e)}`, e);
        onErrorCallback({ error: getErrorMessage(e) });
      }
    }
  },
};

import axios from "axios";
import {
  forex_symbols,
  indices_symbols,
  commodity_symbols,
  crypto_symbols,
} from "../data/symbols";
import { chain } from "lodash";
import moment from "moment-timezone";
import { API_URL } from "./const";
const currency_country = {
  AUD: ["australia"],
  CAD: ["canada"],
  CHF: ["switzerland"],
  CZK: ["czech"],
  EUR: ["germany", "france"],
  GBP: ["united-kingdom"],
  HKD: ["hong-kong"],
  HUF: ["hungary"],
  ILS: ["israel"],
  JPY: ["japan"],
  MXN: ["mexico"],
  NOK: ["norway"],
  NZD: ["new-zealand"],
  PLN: ["poland"],
  RUB: ["russia"],
  SEK: ["sweden"],
  SGD: ["singapore"],
  THB: ["thailand"],
  TRY: ["turkey"],
  USD: ["united-states"],
  ZAR: ["south-africa"],
};

const country_flag = {
  australia: "https://s3-symbol-logo.tradingview.com/country/AU.svg",
  canada: "https://s3-symbol-logo.tradingview.com/country/CA.svg",
  switzerland: "https://s3-symbol-logo.tradingview.com/country/CH.svg",
  germany: "https://s3-symbol-logo.tradingview.com/country/EU.svg",
  "united-kingdom": "https://s3-symbol-logo.tradingview.com/country/UK.svg",
  france: "https://s3-symbol-logo.tradingview.com/country/EU.svg",
  "hong-kong": "https://s3-symbol-logo.tradingview.com/country/HK.svg",
  hungary: "https://s3-symbol-logo.tradingview.com/country/HU.svg",
  israel: "https://s3-symbol-logo.tradingview.com/country/IL.svg",
  japan: "https://s3-symbol-logo.tradingview.com/country/JP.svg",
  norway: "https://s3-symbol-logo.tradingview.com/country/NO.svg",
  "new-zealand": "https://s3-symbol-logo.tradingview.com/country/NZ.svg",
  poland: "https://s3-symbol-logo.tradingview.com/country/PL.svg",
  russia: "https://s3-symbol-logo.tradingview.com/country/RU.svg",
  sweden: "https://s3-symbol-logo.tradingview.com/country/SE.svg",
  singapore: "https://s3-symbol-logo.tradingview.com/country/SG.svg",
  thailand: "https://s3-symbol-logo.tradingview.com/country/TH.svg",
  "united-states": "https://s3-symbol-logo.tradingview.com/country/US.svg",
  "south-africa": "https://s3-symbol-logo.tradingview.com/country/ZA.svg",
};

const isLoggingEnabled = true;
export function logMessage(message, ...args) {
  if (isLoggingEnabled) {
    const now = new Date();
    if (args.length > 0) {
      console.log(
        `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`,
        args
      );
    } else {
      console.log(
        `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`
      );
    }
  }
}

export function logErrorMessage(message, ...args) {
  const now = new Date();
  if (args.length > 0) {
    console.error(
      `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`,
      args
    );
  } else {
    console.error(
      `${now.toLocaleTimeString()}.${now.getMilliseconds()}> ${message}`
    );
  }
}

export function getErrorMessage(error) {
  if (error === undefined) {
    return "";
  } else if (typeof error === "string") {
    return error;
  }
  return error.message;
}

// Makes requests to TraderMade API
export function makeApiRequest(path, data = {}, baseURL = API_URL, token) {
  return new Promise((resolve, error) => {
    try {
      let config = {
        url: `${baseURL}/v1/journal/${path}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": token,
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      axios
        .request(config)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((e) => {
          reject(e.message);
        });
    } catch (err) {
      // changed 'error' to 'err' to avoid confusion with the parameter 'error'
      reject(`TraderMade request error: ${err.message}`); // changed 'error.status' to 'err.message'
    }
  });
}

export function getDependencyCountries(currency) {
  let countries = [];
  if (currency_country[currency]) {
    countries = [...currency_country[currency]];
  }
  return countries;
}

export function getFlagFromCountry(country) {
  return country_flag[country];
}

export const allSymbols = {
  ...forex_symbols,
  ...indices_symbols,
  ...commodity_symbols,
  //...crypto_symbols
};

export function transformSymbol(symbol) {
  return symbol.replace("/", "");
}

export const mt5Symbols = chain(allSymbols)
  .keys()
  .mapKeys(transformSymbol)
  .value();

// Symbol getter function
export function getSymbol(symbol) {
  return allSymbols[symbol] ? allSymbols[symbol] : null;
}

// Symbol type getter function
export function getSymbolType(symbol) {
  const sym = getSymbol(symbol);
  return sym ? sym.type : null;
}

export function formatDateTimeWithTimezone(dateTime, timezone) {
  return moment(dateTime).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function isValidSymbol(symbol) {
  const symbolInfo = getSymbol(symbol);
  return !!symbolInfo;
}

export function hasMinQty(qty) {
  if ((qty.toString().split(".")[1] || "").length <= 2) {
    return { isValidQty: true, message: "Valid volume." };
  } else {
    return { isValidQty: false, message: "Invalid volume." };
  }
}

export function multiply(a, b) {
  var commonMultiplier = 1000000;

  a *= commonMultiplier;
  b *= commonMultiplier;

  return String((a * b) / (commonMultiplier * commonMultiplier));
}

export const getCookie = (key) => {
  try {
    const cookies = parseCookies();
    return cookies[key];
  } catch (error) {
    console.error("Error in getting cookie:", error);
    return "";
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting ${key} in localStorage:`, error);
  }
};

export const getParsedStorage = (dataName) => {
  try {
    const data = localStorage.getItem(dataName);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error(`Error getting ${dataName} from localStorage:`, error);
    return null;
  }
};

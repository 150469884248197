import React, { useEffect, useRef } from "react";
import TradingView from "../charting_library/charting_library.standalone";
import { dataFeeder } from "../trading-view/datafeed";

function getParameterByName(name) {
  let val = window.localStorage.getItem(name);
  if (!val && name === "theme") {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    )
      return "dark";
    else return "light";
  }
  return val;
}

export const TVChartContainer = ({ symbols }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/jsx";
    script.src = "%PUBLIC_URL%/charting_library/charting_library.js";
    document.head.appendChild(script);

    let widget = (window.tvWidget = new TradingView.widget({
      symbol: symbols, // default symbol
      interval: "5", // default interval
      fullscreen: true, // displays the chart in the fullscreen mode
      container: "tv_chart_container",
      datafeed: dataFeeder,
      library_path: "/charting_library/",
      locale: "en",
      disabled_features: [
        "items_favoriting",
        "show_object_tree",
        "header_symbol_search",
        " header_screenshot",
        "save_chart_properties_to_local_storage",
        "header_compare",
        "create_volume_indicator_by_default",
        "go_to_date",
        "scales_date_format",
        "disable_resolution_rebuild",
        "lock_visible_time_range_on_resize",
        "header_resolutions",
      ],
      charts_storage_api_version: "1.1",
      client_id: "trading_platform_demo",
      user_id: "public_user",
      theme: getParameterByName("theme"),
      time_frames: [],
      overrides: {
        "tradingProperties.extendLeft": false,
        "tradingProperties.horizontalAlignment": 1,
      },
      widgetbar: {
        details: false,
        news: false,
        watchlist: false,
        datawindow: false,
      },
    }));

    //Do not forget to remove the script on unmounting the component!
    return () => script.remove();
  }, []); //eslint-disable-line

  return <div id="tv_chart_container"></div>;
};
